<app-header></app-header>
<div class="container">
  <h1>{{ "CreateEditMandatorComponent.headline" | translate }}</h1>
  <div class="row">
    <div class="col-2">
      <app-sidebar
        (handleSwitchComponent)="switchComponent($event)"
      ></app-sidebar>
    </div>
    <div class="col-10">
      <div class="tab-content mt-4" id="myTabContent">
        <app-company-and-order
          #companyAndOrder
          [ngStyle]="{
            display: activeComponent === 'companyAndOrder' ? 'block' : 'none'
          }"
        ></app-company-and-order>
        <app-order-details
          #orderDetails
          [ngStyle]="{
            display: activeComponent === 'orderDetails' ? 'block' : 'none'
          }"
        ></app-order-details>
        <app-arbeits-verzeichnisse
          #ArbeitsVerzeichnisse
          [ngStyle]="{
            display:
              activeComponent === 'arbeits-verzeichnisse' ? 'block' : 'none'
          }"
        ></app-arbeits-verzeichnisse>
        <app-contact-person
          #contactPerson
          [ngStyle]="{
            display: activeComponent === 'contactPerson' ? 'block' : 'none'
          }"
        ></app-contact-person>
        <app-daten-transfer
          #datenTransfer
          [ngStyle]="{
            display: activeComponent === 'daten-transfer' ? 'block' : 'none'
          }"
        ></app-daten-transfer>
        <app-fiscal-year
          #fiscalYear
          [ngStyle]="{
            display: activeComponent === 'fiscalYear' ? 'block' : 'none'
          }"
        ></app-fiscal-year>
        <app-advanced-details
          #advancedDetails
          [ngStyle]="{
            display: activeComponent === 'advancedDetails' ? 'block' : 'none'
          }"
        ></app-advanced-details>
      </div>
    </div>
  </div>
  <div class="row my-5">
    <div class="col-12 d-flex justify-content-center">
      {{ "CreateEditMandatorComponent.status" | translate }}
    </div>
    <div class="col-6">
      <!-- <div class="btn btn-warning">Löschen</div> -->
      <div class="btn btn-danger">{{ "general.btnCancel" | translate }}</div>
    </div>
    <div class="col-6 d-flex justify-content-end">
      <button (click)="submitForms()" class="btn btn-success">
        {{ "general.btnSave" | translate }}
      </button>
    </div>
  </div>
</div>
