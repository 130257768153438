import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private navigationService: NavigationService
  ) {}

  // Functional guard for main route protection
  canActivate(): boolean {
    if (this.authService.isAuthenticated()) {
      return true;
    } else {
      this.navigationService.redirectOnLogout();
      return false;
    }
  }
}
