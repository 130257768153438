<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
@if(!isLoading){
<app-header></app-header>
<div class="container-fluid">
  <h3>{{ "MandatorOverviewComponent.headline" | translate }}</h3>
  <hr class="border-0" />
  <div class="row">
    <div class="col-12 col-sm-4">
      <button
        type="button"
        [disabled]="!isAnyMandatorSelected()"
        (click)="editSelectedMandator()"
        class="btn btn-primary btn-sm me-3"
      >
        {{ "general.btnAdd" | translate }}
      </button>
      <button
        type="button"
        [disabled]="!isAnyMandatorSelected()"
        (click)="downloadControllingTool()"
        class="btn btn-primary btn-sm"
      >
        {{ "MandatorOverviewComponent.downloadControllingTool" | translate }}
      </button>
    </div>
  </div>
  <div class="mt-3">
    {{ "MandatorOverviewComponent.welcome" | translate }}
    {{ userInfo?.fullName }}
  </div>
</div>
}
