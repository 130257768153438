import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BierdeckelApiService } from './bierdeckel-api.service';
import { PreLoginData } from 'src/app/shared/models/mandator.model';
import { UserPortalType } from 'src/app/shared/models/bierdeckel-api.model';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MandatorService extends BierdeckelApiService {
  public readonly defaultMandatorId = '00000000-0000-0000-0000-000000000000';

  loginMandatorAPI(
    mandatorId: string,
    loginName: string,
    password: string,
    machineName: string,
    applicationProcessID: number
  ): Observable<string> {
    const url = `${this.apiUrl}/${mandatorId}/Login/PasswordCredentials/Consultant?loginName=${loginName}&password=${password}&machineName=${machineName}&applicationProcessID=${applicationProcessID}`;
    return super.makeHttpRequest(url, 'POST', 'text');
  }

  getMandatorList(mandatorId: string): Observable<void> {
    const url = `${this.apiUrl}/${mandatorId}/Customer/All/BasicItem`;
    return super.makeHttpRequest(url, 'GET');
  }

  getMandator(mandatorId: string, customerId: string): Observable<void> {
    const url = `${this.apiUrl}/${mandatorId}/Customer/${customerId}/DisplayItem`;
    return super.makeHttpRequest(url, 'GET');
  }

  getMandatorPreLoginDataDisplayItem(
    mandatorId: string,
    userPortalType: UserPortalType
  ): Observable<PreLoginData> {
    const url = `${this.apiUrl}/Mandantor/${mandatorId}/PreLoginDataDisplayItem?cultureName=de&includeImageData=true&userPortalType=${userPortalType}`;
    return super.makeHttpRequest(url, 'GET');
  }

  deleteMandator(id: string) {
    return this.http.delete(this.apiUrl + '/' + id);
  }

  addMandator(data: any) {
    return this.http.post(this.apiUrl, data);
  }

  updateMandator(id: string, data: any) {
    return this.http.put(this.apiUrl + '/' + id, data);
  }
}
