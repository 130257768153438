import { Component } from '@angular/core';

@Component({
  selector: 'app-daten-transfer',
  templateUrl: './daten-transfer.component.html',
  styleUrls: ['./daten-transfer.component.css']
})
export class DatenTransferComponent {

}
