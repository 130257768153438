import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Component({
  selector: 'app-user-type-selection',
  templateUrl: './user-type-selection.component.html',
  styleUrl: './user-type-selection.component.css',
})
export class UserTypeSelectionComponent implements OnInit {
  constructor(
    private navigationService: NavigationService,
    public authService: AuthService,
    public translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.navigationService.redirectOnLogin();
    }
  }
}
