import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MandatorService } from 'src/app/shared/services/mandator.service';
import { PreLoginData } from 'src/app/shared/models/mandator.model';
import { UserPortalType } from 'src/app/shared/models/bierdeckel-api.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-login-consultant',
  templateUrl: './login-consultant.component.html',
  styleUrls: ['./login-consultant.component.css'],
})
export class LoginConsultantComponent implements OnInit {
  username?: string;
  password?: string;
  mandatorId?: string;
  preLoginData?: PreLoginData;
  machineName?: string;
  appProcessId?: number;
  userPortalType: UserPortalType = 'Consultant';
  loading: boolean = false;
  errorMessageLoginFail?: string;
  errorMessageUsername?: string;
  errorMessagePassword?: string;

  constructor(
    private authService: AuthService,
    private mandatorService: MandatorService,
    private router: Router,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    public translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.navigationService.redirectOnLogin();
    } else {
      this.activatedRoute.params.subscribe((params) => {
        this.navigationService.setParams(params);
      });
      this.mandatorId = this.mandatorService.getMandatorId();

      this.machineName = this.mandatorService.getBrowserAndOS();
      this.appProcessId = Math.floor(Math.random() * Math.max(1000, 1)) + 1;
    }
  }

  async onLogin() {
    this.loading = true;
    this.resetErrorMessages();

    if (!this.username || this.username.trim() === '') {
      this.translateErrorMessageUsername();
      this.loading = false;
      return;
    }
    if (!this.password || this.password.trim() === '') {
      this.translateErrorMessagePassword();
      this.loading = false;
      return;
    }

    try {
      const accessToken = await firstValueFrom(
        this.mandatorService.loginMandatorAPI(
          this.mandatorId!,
          this.username,
          this.password,
          this.machineName!,
          this.appProcessId!
        )
      );

      if (accessToken) {
        localStorage.setItem(environment.lsAccessTokenKey, accessToken);
        this.router.navigate([
          `/${this.translateService.currentLang}/consultant/${this.mandatorId}/start`,
        ]);
      } else {
        throw new Error('Access token not found!');
      }
    } catch (error) {
      this.translateErrorMessageLoginFail();
    } finally {
      this.loading = false;
    }
  }

  translateErrorMessageLoginFail() {
    this.translateService
      .get(['LoginConsultantAndCustomerComponent.errorMessageLoginFail'])
      .subscribe((translations) => {
        this.errorMessageLoginFail =
          translations[
            'LoginConsultantAndCustomerComponent.errorMessageLoginFail'
          ];
      });
  }

  translateErrorMessageUsername() {
    this.translateService
      .get(['LoginConsultantAndCustomerComponent.errorMessageUsername'])
      .subscribe((translations) => {
        this.errorMessageUsername =
          translations[
            'LoginConsultantAndCustomerComponent.errorMessageUsername'
          ];
        console.log(this.errorMessageUsername);
      });
  }

  translateErrorMessagePassword() {
    this.translateService
      .get(['LoginConsultantAndCustomerComponent.errorMessagePassword'])
      .subscribe((translations) => {
        this.errorMessagePassword =
          translations[
            'LoginConsultantAndCustomerComponent.errorMessagePassword'
          ];
      });
  }

  resetErrorMessages() {
    this.errorMessageLoginFail = undefined;
    this.errorMessageUsername = undefined;
    this.errorMessagePassword = undefined;
  }
}
