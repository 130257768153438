<div
  class="bg-light rounded-end h-100"
  [ngClass]="{
    'position-relative': !sidebarCollapsed
  }"
  [ngStyle]="{
          'min-width': !sidebarCollapsed ? '196px' : '0px',
          'z-index': '1',
          'max-width': sidebarWidth,
        }"
>
  <div class="sticky-top">
    <div
      class="d-flex"
      [ngClass]="
        !sidebarCollapsed ? 'justify-content-end' : 'justify-content-center'
      "
    >
      <i
        role="button"
        class="bi text-secondary fs-3 cursor-pointer"
        (click)="sidebarCollapsed = !sidebarCollapsed"
        [class.d-none]="hideSidebar"
        [ngClass]="
          !sidebarCollapsed
            ? 'bi-caret-right-square me-2'
            : 'bi-caret-left-square'
        "
      ></i>
    </div>
    <nav class="navbar justify-content-start">
      <ul class="navbar-nav" [ngClass]="{ 'd-none': sidebarCollapsed }">
        <li class="nav-item" *ngFor="let tab of tabElements; let i = index">
          <a
            class="nav-link py-1 px-2 cursor-pointer"
            [id]="tab.value"
            (click)="selectTab(tab.value)"
            [ngClass]="{ active: activeTab === tab.value }"
          >
            {{ i + 1 }}. {{ tab.label }}
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>
