import { Component, ViewChild, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MandatorService } from 'src/app/shared/services/mandator.service';

@Component({
  selector: 'app-company-and-order',
  templateUrl: './company-and-order.component.html',
})
export class CompanyAndOrderComponent implements OnInit {
  @ViewChild('companyAndOrder') form!: NgForm;

  mandatorId = '';
  companyNameLong = '';
  companyNameShort = '';
  street = '';
  postalCode = '';
  city = '';
  country = '';
  mail = '';
  phone = '';
  website = '';
  industry = '';
  consultantName = '';

  consultantElements = [
    'Test-consultant Compumaster',
    'Martin Herber',
    'Rainer Dreier',
    'DSA AFDS',
  ];

  constructor(
    private mandatorService: MandatorService,
    private activeatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activeatedRoute.params.subscribe((params) => {
      if (params['id']) {
        this.mandatorId = params['id'];
        this.getMandator(params['id']);
      }
    });
  }

  getMandator(id: string) {
    console.log(id);
    // this.mandatorService.getMandator(id).subscribe({
    //   next: (response: any) => {
    //     this.companyNameLong = response.firmaAuftrag.firmaNameLang;
    //     this.companyNameShort = response.firmaAuftrag.firmaNameKurz;
    //     this.street = response.firmaAuftrag.strasse;
    //     this.postalCode = response.firmaAuftrag.plz;
    //     this.city = response.firmaAuftrag.ort;
    //     this.country = response.firmaAuftrag.land;
    //     this.mail = response.firmaAuftrag.email;
    //     this.phone = response.firmaAuftrag.telefon;
    //     this.website = response.firmaAuftrag.website;
    //     this.industry = response.firmaAuftrag.branche;
    //     this.consultantName = response.firmaAuftrag.berater;
    //   },
    //   error: (error) => console.log(error),
    // });
  }

  submitData() {
    if (this.form.valid) {
      if (this.mandatorId) {
        this.mandatorService
          .updateMandator(this.mandatorId, this.form.value)
          .subscribe({
            next: (response) => console.log(response),
            error: (error) => console.log(error),
          });
      } else {
        return this.form.value;
      }
    }
  }
}
