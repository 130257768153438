<form (ngSubmit)="submitData()" #companyAndOrder="ngForm">
  <h3>{{ "CompanyAndOrderComponent.headline" | translate }}</h3>
  <hr class="border-0" />
  <div class="form-group mb-3">
    <label for="companyNameLong">{{
      "CompanyAndOrderComponent.labelCompanyNameLong" | translate
    }}</label>
    <input
      type="text"
      class="form-control"
      id="companyNameLong"
      name="companyNameLong"
      [(ngModel)]="companyNameLong"
    />
  </div>
  <div class="form-group mb-3">
    <label for="companyNameShort">{{
      "CompanyAndOrderComponent.labelCompanyNameShort" | translate
    }}</label>
    <input
      type="text"
      class="form-control"
      id="companyNameShort"
      name="companyNameShort"
      [(ngModel)]="companyNameShort"
    />
  </div>
  <hr class="border-0" />
  <div class="form-group mb-3">
    <label for="street">{{
      "CompanyAndOrderComponent.labelStreet" | translate
    }}</label>
    <input
      type="text"
      class="form-control"
      id="street"
      name="street"
      [(ngModel)]="street"
    />
  </div>
  <div class="form-group mb-3">
    <label for="postalCode">{{
      "CompanyAndOrderComponent.labelPostalCode" | translate
    }}</label>
    <input
      type="text"
      class="form-control"
      id="postalCode"
      name="postalCode"
      [(ngModel)]="postalCode"
    />
  </div>
  <div class="form-group mb-3">
    <label for="city">{{
      "CompanyAndOrderComponent.labelCity" | translate
    }}</label>
    <input
      type="text"
      class="form-control"
      id="city"
      name="city"
      [(ngModel)]="city"
    />
  </div>
  <div class="form-group mb-3">
    <label for="country">{{
      "CompanyAndOrderComponent.labelCountry" | translate
    }}</label>
    <input
      type="text"
      class="form-control"
      id="country"
      name="country"
      [(ngModel)]="country"
    />
  </div>
  <hr class="border-0" />
  <div class="form-group mb-3">
    <label for="mail">{{
      "CompanyAndOrderComponent.labelMail" | translate
    }}</label>
    <input
      type="mail"
      class="form-control"
      id="mail"
      name="mail"
      [(ngModel)]="mail"
    />
  </div>
  <div class="form-group mb-3">
    <label for="phone">{{
      "CompanyAndOrderComponent.labelPhone" | translate
    }}</label>
    <input
      type="text"
      class="form-control"
      id="phone"
      name="phone"
      [(ngModel)]="phone"
    />
  </div>
  <div class="form-group mb-3">
    <label for="website">{{
      "CompanyAndOrderComponent.labelWebsite" | translate
    }}</label>
    <input
      type="url"
      class="form-control"
      id="website"
      name="website"
      [(ngModel)]="website"
    />
  </div>
  <hr class="border-0" />
  <div class="form-group mb-3">
    <label for="industry">{{
      "CompanyAndOrderComponent.labelIndustry" | translate
    }}</label>
    <input
      type="text"
      class="form-control"
      id="industry"
      name="industry"
      [(ngModel)]="industry"
    />
  </div>
  <hr class="border-0" />
  <div class="form-group mb-3">
    <label for="consultantName">{{
      "CompanyAndOrderComponent.labelConsultantName" | translate
    }}</label>
    <select
      class="form-control"
      id="consultantName"
      name="consultantName"
      [(ngModel)]="consultantName"
    >
      <option value="" disabled selected>
        {{ "general.firstOption" | translate }}
      </option>
      <option
        *ngFor="let consultant of consultantElements"
        [value]="consultant.toLowerCase().replace(' ', '-')"
      >
        {{ consultant }}
      </option>
    </select>
  </div>
</form>
