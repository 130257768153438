<div
  class="full-screen-container d-flex align-content-between flex-wrap justify-content-center pt-5"
>
  <div class="container shadow">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-6 col-12 bg-white left-container rounded-start">
            @if(preLoginData?.logoImageData){
            <img
              [src]="'data:image/png;base64,' + preLoginData?.logoImageData"
              [alt]="preLoginData?.name"
              class="pre-login-image-position"
            />
            }@else{
            <h3 class="text-start mb-5">
              {{ preLoginData?.name }}
            </h3>
            }
            <div class="text-cmGold fs-1 mb-2">
              <div class="poppins-light">
                {{
                  "LoginConsultantAndCustomerComponent.headlineHello"
                    | translate
                }}
              </div>
              <div class="poppins-bold">
                {{
                  "LoginConsultantAndCustomerComponent.headlineWelcome"
                    | translate
                }}
              </div>
            </div>
            <form (ngSubmit)="onLogin()">
              <div
                class="form-group border-cmGold border-bottom"
                [class]="{ 'mb-3': !errorMessageUsername }"
              >
                <div class="row m-0">
                  <div class="col-xl-1 col-2 p-0">
                    <div class="d-flex justify-content-center pt-1">
                      <ng-icon name="heroUser" class="text-cmGold"></ng-icon>
                    </div>
                  </div>
                  <div class="col-xl-11 col-10 p-0">
                    <input
                      type="text"
                      class="form-control border border-0 fs-5 px-0 text-cmGold"
                      id="username"
                      name="username"
                      autocomplete="login consultant username"
                      placeholder="{{
                        'LoginConsultantAndCustomerComponent.labelUsername'
                          | translate
                      }}"
                      [(ngModel)]="username"
                      [disabled]="loading"
                    />
                  </div>
                </div>
              </div>
              @if(errorMessageUsername){
              <div class="mb-3 text-danger">
                {{ errorMessageUsername }}
              </div>
              }
              <div
                class="form-group border-cmGold border-bottom"
                [class]="{
                  'mb-5': !errorMessagePassword && !errorMessageLoginFail
                }"
              >
                <div class="row m-0">
                  <div class="col-xl-1 col-2 p-0">
                    <div class="d-flex justify-content-center pt-1">
                      <ng-icon name="heroKey" class="text-cmGold"></ng-icon>
                    </div>
                  </div>
                  <div class="col-xl-11 col-10 p-0">
                    <input
                      type="password"
                      class="form-control border border-0 fs-5 px-0 text-cmGold"
                      id="password"
                      name="password"
                      autocomplete="login consultant password"
                      placeholder="{{
                        'LoginConsultantAndCustomerComponent.labelPassword'
                          | translate
                      }}"
                      [(ngModel)]="password"
                      [disabled]="loading"
                    />
                  </div>
                </div>
              </div>
              @if(errorMessagePassword){
              <div class="mb-4 text-danger">
                {{ errorMessagePassword }}
              </div>
              } @if(errorMessageLoginFail){
              <div class="mt-3 mb-3 text-danger">
                {{ errorMessageLoginFail }}
              </div>
              }
              <button
                type="submit"
                class="btn btn-primary rounded-0 px-3"
                style="width: 170px"
                [disabled]="loading || !(this.username && this.password)"
              >
                @if (loading){
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                }
                {{ "general.btnLogin" | translate }}
              </button>
            </form>
          </div>
          <div class="col-md-6 col-12 bg-placeholder-image rounded-end">
            <div class="w-100"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <app-copyright-footer
      [mandatorId]="mandatorId!"
      [userPortalType]="userPortalType"
      (preLoginDataOutput)="preLoginData = $event"
      textColor="text-secondary"
    ></app-copyright-footer>
  </div>
  <app-support-button></app-support-button>
</div>
