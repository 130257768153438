<header>
  <nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
    <div class="container-fluid">
      <a
        class="navbar-brand"
        [routerLink]="[
          '/' + translateService.currentLang + '/consultant/' + mandatorId
        ]"
        >Navbar</a
      >
      <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse"
        [ngClass]="{ show: !isNavbarCollapsed }"
      >
        <ul class="navbar-nav">
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="[
                '/' +
                  translateService.currentLang +
                  '/consultant/' +
                  mandatorId +
                  '/create-customer'
              ]"
              >{{ "HeaderComponent.createMandator" | translate }}</a
            >
          </li>
        </ul>
        <button class="ms-auto btn btn-warning" (click)="authService.logout()">
          {{ "HeaderComponent.logout" | translate }}
        </button>
      </div>
    </div>
  </nav>
</header>
