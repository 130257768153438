<h3>{{ "ContactPersonComponent.headline" | translate }}</h3>
<hr class="border-0" />
<div class="row">
  <div class="col-12 col-sm-4">
    <div class="d-flex justify-content-between">
      <button
        type="button"
        class="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target="#contactModal"
        (click)="resetForm()"
      >
        {{ "general.btnAdd" | translate }}
      </button>
      <button
        type="button"
        [disabled]="!isAnyContactSelected()"
        (click)="deleteSelectedContact()"
        class="btn btn-primary btn-sm"
      >
        {{ "general.btnDelete" | translate }}
      </button>
      <button
        type="button"
        [disabled]="!isAnyContactSelected()"
        (click)="editSelectedContact()"
        data-bs-toggle="modal"
        data-bs-target="#contactModal"
        class="btn btn-primary btn-sm"
      >
        {{ "general.btnEdit" | translate }}
      </button>
    </div>
  </div>
</div>
<div class="table-responsible">
  <table class="table table-striped text-nowrap">
    <thead>
      <tr>
        <th></th>
        <th>
          {{ "ContactPersonComponent.tableHeaders.lastName" | translate }}
        </th>
        <th>{{ "ContactPersonComponent.tableHeaders.phone" | translate }}</th>
        <th>
          {{ "ContactPersonComponent.tableHeaders.smartphone" | translate }}
        </th>
        <th>{{ "ContactPersonComponent.tableHeaders.mail" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="contactPersonElements.length > 0; else noContacts">
        <tr *ngFor="let contact of contactPersonElements; let i = index">
          <td>
            <input
              type="checkbox"
              [checked]="isSelectedContact(i)"
              (change)="toggleContact(i, contact)"
              [attr.data-index]="i"
            />
          </td>
          <td>
            {{ contact.familyName }}
          </td>
          <td>
            {{ contact.phone }}
          </td>
          <td>
            {{ contact.mobile }}
          </td>
          <td>
            {{ contact.mail }}
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
<ng-template #noContacts>
  <tr>
    <td colspan="5" class="text-center">
      {{ "ContactPersonComponent.noContracts" | translate }}
    </td>
  </tr>
</ng-template>
<!-- Modal -->
<div
  class="modal fade"
  id="contactModal"
  tabindex="-1"
  aria-labelledby="modalHeadline"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="modalHeadline">
          {{ "ContactPersonComponent.modalHeadline" | translate }}
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <form (ngSubmit)="addContact()" #contactForm="ngForm">
        <div class="modal-body">
          <div class="row mb-2 py-1">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="company">{{
                "ContactPersonComponent.lableCompany" | translate
              }}</label>
            </div>
            <div class="col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                id="company"
                name="company"
                [(ngModel)]="company"
              />
            </div>
          </div>
          <div class="row mb-2 py-1 bg-light">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="salutationStyle">{{
                "ContactPersonComponent.labelSalutationStyle" | translate
              }}</label>
            </div>
            <div class="col-12 col-md-6">
              <select
                class="form-control"
                id="salutationStyle"
                name="salutationStyle"
                [(ngModel)]="salutationStyle"
              >
                <option value="" disabled selected>
                  {{ "general.firstOption" | translate }}
                </option>
                <option>
                  {{
                    "ContactPersonComponent.salutationStyle_options.personaly"
                      | translate
                  }}
                </option>
                <option>
                  {{
                    "ContactPersonComponent.salutationStyle_options.formally"
                      | translate
                  }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-2 py-1">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="salutation">{{
                "ContactPersonComponent.labelSalutation" | translate
              }}</label>
            </div>
            <div class="col-12 col-md-6">
              <select
                class="form-control"
                id="salutation"
                name="salutation"
                [(ngModel)]="salutation"
              >
                <option value="" disabled selected>
                  {{ "general.firstOption" | translate }}
                </option>
                <option value="frau">
                  {{
                    "ContactPersonComponent.salutation_options.her" | translate
                  }}
                </option>
                <option value="herr">
                  {{
                    "ContactPersonComponent.salutation_options.his" | translate
                  }}
                </option>
                <option value="divers">
                  {{
                    "ContactPersonComponent.salutation_options.various"
                      | translate
                  }}
                </option>
                <option value="team">
                  {{
                    "ContactPersonComponent.salutation_options.team" | translate
                  }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-2 py-1 bg-light">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="title">{{
                "ContactPersonComponent.labelTitle" | translate
              }}</label>
            </div>
            <div class="col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                id="title"
                name="title"
                [(ngModel)]="title"
              />
            </div>
          </div>
          <div class="row mb-2 py-1">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="firstname">{{
                "ContactPersonComponent.labelFirstname" | translate
              }}</label>
            </div>
            <div class="col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                id="firstname"
                name="firstname"
                [(ngModel)]="firstname"
              />
            </div>
          </div>
          <div class="row mb-2 py-1 bg-light">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="familyName">{{
                "ContactPersonComponent.labelFamilyName" | translate
              }}</label>
            </div>
            <div class="col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                id="familyName"
                name="familyName"
                [(ngModel)]="familyName"
              />
            </div>
          </div>
          <div class="row mb-2 py-1">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="nameAddition">{{
                "ContactPersonComponent.labelNameAddition" | translate
              }}</label>
            </div>
            <div class="col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                id="nameAddition"
                name="nameAddition"
                [(ngModel)]="nameAddition"
              />
            </div>
          </div>
          <div class="row mb-2 py-1 bg-light">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="mail">{{
                "ContactPersonComponent.labelMail" | translate
              }}</label>
            </div>
            <div class="col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                id="mail"
                name="mail"
                [(ngModel)]="mail"
              />
            </div>
          </div>
          <div class="row mb-2 py-1">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="phone">{{
                "ContactPersonComponent.labelPhone" | translate
              }}</label>
            </div>
            <div class="col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                id="phone"
                name="phone"
                [(ngModel)]="phone"
              />
            </div>
          </div>
          <div class="row mb-2 py-1 bg-light">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="mobile">{{
                "ContactPersonComponent.labelMobile" | translate
              }}</label>
            </div>
            <div class="col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                id="mobile"
                name="mobile"
                [(ngModel)]="mobile"
              />
            </div>
          </div>
          <div class="row mb-2 py-1">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="fax">{{
                "ContactPersonComponent.labelFax" | translate
              }}</label>
            </div>
            <div class="col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                id="fax"
                name="fax"
                [(ngModel)]="fax"
              />
            </div>
          </div>
          <div class="row mb-2 py-1 bg-light">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="companyPosition">{{
                "ContactPersonComponent.labelCompanyPosition" | translate
              }}</label>
            </div>
            <div class="col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                id="companyPosition"
                name="companyPosition"
                [(ngModel)]="companyPosition"
              />
            </div>
          </div>
          <div class="row mb-2 py-1">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="street">{{
                "ContactPersonComponent.labelStreet" | translate
              }}</label>
            </div>
            <div class="col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                id="street"
                name="street"
                [(ngModel)]="street"
              />
            </div>
          </div>
          <div class="row mb-2 py-1 bg-light">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="postalCode">{{
                "ContactPersonComponent.labelPostalCode" | translate
              }}</label>
            </div>
            <div class="col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                id="postalCode"
                name="postalCode"
                [(ngModel)]="postalCode"
              />
            </div>
          </div>
          <div class="row mb-2 py-1">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="location">{{
                "ContactPersonComponent.labelCity" | translate
              }}</label>
            </div>
            <div class="col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                id="location"
                name="location"
                [(ngModel)]="location"
              />
            </div>
          </div>
          <div class="row mb-2 py-1 bg-light">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="country">{{
                "ContactPersonComponent.labelCountry" | translate
              }}</label>
            </div>
            <div class="col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                id="country"
                name="country"
                [(ngModel)]="country"
              />
            </div>
          </div>
          <div class="row mb-2 py-1">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="website">{{
                "ContactPersonComponent.website" | translate
              }}</label>
            </div>
            <div class="col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                id="website"
                name="website"
                [(ngModel)]="website"
              />
            </div>
          </div>
          <div class="row mb-2 py-1 bg-light">
            <div class="col-12 col-md-6">
              <label for="bdReportingRole">{{
                "ContactPersonComponent.labelBdReportingRole" | translate
              }}</label>
            </div>
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-12">
                  <input
                    type="checkbox"
                    id="mainContact"
                    name="mainContact"
                    [(ngModel)]="mainContact"
                  />
                  <label class="ms-2" for="mainContact">{{
                    "ContactPersonComponent.bdReportingRole_options.mainContact"
                      | translate
                  }}</label>
                </div>
                <div class="col-12">
                  <input
                    type="checkbox"
                    id="additionalReportingRecipient"
                    name="additionalReportingRecipient"
                    [(ngModel)]="additionalReportingRecipient"
                  />
                  <label class="ms-2" for="additionalReportingRecipient">{{
                    "ContactPersonComponent.bdReportingRole_options.additionalReportingRecipient"
                      | translate
                  }}</label>
                </div>
                <div class="col-12">
                  <input
                    type="checkbox"
                    id="fibuRequest"
                    name="fibuRequest"
                    [(ngModel)]="fibuRequest"
                  />
                  <label class="ms-2" for="fibuRequest">{{
                    "ContactPersonComponent.bdReportingRole_options.fibuRequest"
                      | translate
                  }}</label>
                </div>
                <div class="col-12">
                  <input
                    type="checkbox"
                    id="fibuRequestCCAlways"
                    name="fibuRequestCCAlways"
                    [(ngModel)]="fibuRequestCCAlways"
                  />
                  <label class="ms-2" for="fibuRequestCCAlways">{{
                    "ContactPersonComponent.bdReportingRole_options.fibuRequestCCAlways"
                      | translate
                  }}</label>
                </div>
                <div class="col-12">
                  <input
                    type="checkbox"
                    id="fibuRequestCCReminder"
                    name="fibuRequestCCReminder"
                    [(ngModel)]="fibuRequestCCReminder"
                  />
                  <label class="ms-2" for="fibuRequestCCReminder">{{
                    "ContactPersonComponent.bdReportingRole_options.fibuRequestCCReminder"
                      | translate
                  }}</label>
                </div>
                <div class="col-12">
                  <input
                    type="checkbox"
                    id="taxConsultant"
                    name="taxConsultant"
                    [(ngModel)]="taxConsultant"
                  />
                  <label class="ms-2" for="taxConsultant">{{
                    "ContactPersonComponent.bdReportingRole_options.taxConsultant"
                      | translate
                  }}</label>
                </div>
                <div class="col-12">
                  <input
                    type="checkbox"
                    id="invoiceRecipient"
                    name="invoiceRecipient"
                    [(ngModel)]="invoiceRecipient"
                  />
                  <label class="ms-2" for="invoiceRecipient">{{
                    "ContactPersonComponent.bdReportingRole_options.invoiceRecipient"
                      | translate
                  }}</label>
                </div>
                <div class="col-12">
                  <input
                    type="checkbox"
                    id="otherRoleCheckbox"
                    name="otherRoleCheckbox"
                  />
                  <label class="ms-2" for="otherRole">{{
                    "ContactPersonComponent.bdReportingRole_options.otherRole"
                      | translate
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="otherRole"
                    name="otherRole"
                    [(ngModel)]="otherRole"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-2 py-1 bg-light">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="note">{{
                "ContactPersonComponent.labelNote" | translate
              }}</label>
            </div>
            <div class="col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                id="note"
                name="note"
                [(ngModel)]="note"
              />
            </div>
          </div>
          <div class="row mb-2 py-1">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="reasonDatasaving">{{
                "ContactPersonComponent.labelReasonDatasaving" | translate
              }}</label>
            </div>
            <div class="col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                id="reasonDatasaving"
                name="reasonDatasaving"
                [(ngModel)]="reasonDatasaving"
              />
            </div>
          </div>
          <div class="row mb-2 py-1 bg-light">
            <div class="col-12 col-md-6 d-flex align-items-center">
              <label for="refDate">{{
                "ContactPersonComponent.refDate" | translate
              }}</label>
            </div>
            <div class="col-12 col-md-6">
              <input
                type="text"
                class="form-control"
                id="refDate"
                name="refDate"
                [(ngModel)]="refDate"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {{ "general.btnCancel" | translate }}
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="!contactForm.valid"
            data-bs-dismiss="modal"
          >
            {{ "general.btnAdd" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
