<button class="floating-button" (click)="openContextMenu()">
  <ng-icon size="2rem" name="heroUsers" class="text-white mt-2"></ng-icon>
</button>

<div class="context-menu" *ngIf="showContextMenu">
  <ul>
    <li>
      <a
        href="https://extranet.compumaster.de/osticket/open.php"
        target="_blank"
        ><b>{{ "SupportButtonComponent.support" | translate }}:</b>
        {{ "SupportButtonComponent.ticket" | translate }}</a
      >
    </li>
    <li>
      <a href="tel: +49 (0)6742 9799111" target="_blank"
        ><b>{{ "SupportButtonComponent.phone" | translate }}:</b> +49 (0)6742
        9799111</a
      >
    </li>
  </ul>
</div>
