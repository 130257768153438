<div class="text-center align-items-end" [ngClass]="textColor">
  Copyright © 1998 - {{ currentYear }} CompuMaster GmbH @if(preLoginData){ @for
  (link of preLoginData.websiteFooterLinks; track link.key) { |
  <a
    [ngClass]="textColor"
    [href]="link.value"
    [title]="link.key"
    target="_blank"
  >
    {{ link.key }}
  </a>
  } }
</div>
