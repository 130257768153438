import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MandatorViewComponent } from './mandator-view/mandator-view.component';
import { ErrorpageComponent } from './shared/components/errorpage/errorpage.component';
import { AuthGuard } from './guards/auth.guard';
import { UserTypeSelectionComponent } from './user-type-selection/user-type-selection.component';
import { IdentityCheckComponent } from './user-type-selection/identity-check/identity-check.component';
import { CreateEditMandatorComponent } from './mandator-view/create-edit-mandator/create-edit-mandator.component';
import { LoginConsultantComponent } from './user-type-selection/login-consultant/login-consultant.component';
import { LoginCustomerComponent } from './user-type-selection/login-customer/login-customer.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'de',
    pathMatch: 'full',
  },
  {
    path: ':lang',
    children: [
      {
        path: '',
        component: UserTypeSelectionComponent,
      },
      {
        path: 'consultant',
        children: [
          {
            path: '',
            component: IdentityCheckComponent,
          },
          {
            path: ':mandatorId',
            children: [
              {
                path: '',
                component: LoginConsultantComponent,
              },
              {
                path: 'start',
                component: MandatorViewComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'create-customer',
                children: [
                  {
                    path: '',
                    component: CreateEditMandatorComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'order-details',
                    component: CreateEditMandatorComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'contact-person',
                    component: CreateEditMandatorComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'fiscal-year',
                    component: CreateEditMandatorComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'advanced-details',
                    component: CreateEditMandatorComponent,
                    canActivate: [AuthGuard],
                  },
                ],
              },
              {
                path: 'edit-customer/:customerId',
                component: CreateEditMandatorComponent,
                canActivate: [AuthGuard],
              },
            ],
          },
        ],
      },
      {
        path: 'customer',
        children: [
          {
            path: '',
            component: IdentityCheckComponent,
          },
          {
            path: ':mandatorId/:customerId',
            children: [
              {
                path: '',
                component: LoginCustomerComponent,
              },
              {
                path: 'start',
                component: MandatorViewComponent,
                canActivate: [AuthGuard],
              },
            ],
          },
        ],
      },
      {
        path: 'error',
        component: ErrorpageComponent,
      },
      { path: '**', redirectTo: 'error' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
