<form (ngSubmit)="submitData()" #orderDetails="ngForm">
  <h3>{{ "OrderDetailsComponent.headline" | translate }}</h3>
  <hr class="border-0" />
  <div class="row mb-2 py-1">
    <div class="col-12 col-md-6 d-flex align-items-center">
      <div>{{ "OrderDetailsComponent.labelFromMonth" | translate }}</div>
    </div>
    <div class="col-12 col-md-6">
      <div class="row">
        <div class="col-12 col-md-6">
          <select
            class="form-control my-1"
            id="orderFromMonth"
            name="orderFromMonth"
            [(ngModel)]="orderFromMonth"
          >
            <option value="" disabled selected>
              {{ "general.firstOption" | translate }}
            </option>
            <option *ngFor="let month of monthElements" [value]="month.value">
              {{ month.label }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-6">
          <select
            class="form-control my-1"
            id="orderFromYear"
            name="orderFromYear"
            [(ngModel)]="orderFromYear"
          >
            <option value="" disabled selected>
              {{ "general.firstOption" | translate }}
            </option>
            <option *ngFor="let year of yearElements" [value]="year.value">
              {{ year.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-2 py-1 bg-light">
    <div class="col-12 col-md-6 d-flex align-items-center">
      <div>{{ "OrderDetailsComponent.labelUntilMonth" | translate }}</div>
    </div>
    <div class="col-12 col-md-6">
      <div class="row">
        <div class="col-12 col-md-6">
          <select
            class="form-control my-1"
            id="orderToMonth"
            name="orderToMonth"
            [(ngModel)]="orderToMonth"
          >
            <option value="" disabled selected>
              {{ "general.firstOption" | translate }}
            </option>
            <option *ngFor="let month of monthElements" [value]="month.value">
              {{ month.label }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-6">
          <select
            class="form-control my-1"
            id="orderToYear"
            name="orderToYear"
            [(ngModel)]="orderToYear"
          >
            <option value="" disabled selected>
              {{ "general.firstOption" | translate }}
            </option>
            <option *ngFor="let year of yearElements" [value]="year.value">
              {{ year.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-2 py-1">
    <div class="col-12 col-md-6 d-flex align-items-center">
      <div>{{ "OrderDetailsComponent.labelOrderStop" | translate }}</div>
    </div>
    <div class="col-12 col-md-6">
      <input
        type="checkbox"
        id="orderStop"
        name="orderStop"
        [(ngModel)]="orderStop"
      />
    </div>
  </div>
  <div class="row mb-2 py-1 bg-light">
    <div class="col-12 col-md-6 d-flex align-items-center">
      <div>{{ "OrderDetailsComponent.labelOrderRhythm" | translate }}</div>
    </div>
    <div class="col-12 col-md-6">
      <select
        class="form-control"
        id="orderRhythm"
        name="orderRhythm"
        [(ngModel)]="orderRhythm"
      >
        <option value="" disabled selected>
          {{ "general.firstOption" | translate }}
        </option>
        <option *ngFor="let rhythm of rhythmElements" [value]="rhythm.value">
          {{ rhythm.label }}
        </option>
      </select>
    </div>
  </div>
  <div class="row mb-2 py-1">
    <div class="col-12 col-md-6 d-flex align-items-center">
      <div>Genutzter Kontenrahmen</div>
    </div>
    <div class="col-12 col-md-6">
      <select
        class="form-control"
        id="chartOfAccounts"
        name="chartOfAccounts"
        [(ngModel)]="chartOfAccounts"
      >
        <option value="" disabled selected>
          {{ "general.firstOption" | translate }}
        </option>
        <option
          *ngFor="let account of chartOfAccountElements"
          [value]="account.value"
        >
          {{ account.label }}
        </option>
      </select>
    </div>
  </div>
  <div class="row mb-2 py-1 bg-light">
    <div class="col-12 col-md-6 d-flex align-items-center">
      <div>
        {{ "OrderDetailsComponent.labelAdditionalAccountDigits" | translate }}
      </div>
    </div>
    <div class="col-12 col-md-6">
      <select
        class="form-control"
        id="additionalAccountDigits"
        name="additionalAccountDigits"
        [(ngModel)]="additionalAccountDigits"
      >
        <option value="" disabled selected>
          {{ "general.firstOption" | translate }}
        </option>
        <option
          *ngFor="let additionalChartChar of additionalChartCharElements"
          [value]="additionalChartChar.value"
        >
          {{ additionalChartChar.label }}
        </option>
      </select>
    </div>
  </div>
  <div class="row mb-2 py-1">
    <div class="col-12 col-md-6">
      <div>
        {{ "OrderDetailsComponent.labelChartOfAccountsRule" | translate }}
      </div>
    </div>
    <div class="col-12 col-md-6">
      <input
        type="text"
        class="form-control"
        id="chartOfAccountsRule"
        name="chartOfAccountsRule"
        [(ngModel)]="chartOfAccountsRule"
      />
    </div>
  </div>
  <div class="row mb-2 py-1 bg-light">
    <div class="col-12 col-md-6">
      <div>
        {{ "OrderDetailsComponent.labelPreviousYearStructure" | translate }}
      </div>
    </div>
    <div class="col-12 col-md-6">
      <input
        type="checkbox"
        id="previousYearStructure"
        name="previousYearStructure"
        [(ngModel)]="previousYearStructure"
      />
    </div>
  </div>
  <div class="row mb-2 py-1">
    <div class="col-12 col-md-6 d-flex align-items-center">
      <div>
        {{ "OrderDetailsComponent.labelAccountingType" | translate }}
      </div>
    </div>
    <div class="col-12 col-md-6">
      <select
        class="form-control"
        id="accountingType"
        name="accountingType"
        [(ngModel)]="accountingType"
      >
        <option value="" disabled selected>
          {{ "general.firstOption" | translate }}
        </option>
        <option
          *ngFor="let accountingType of accountingTypeElements"
          [value]="accountingType.value"
        >
          {{ accountingType.label }}
        </option>
      </select>
    </div>
  </div>
  <div class="row mb-2 py-1 bg-light">
    <div class="col-12 col-md-6 d-flex align-items-center">
      <div>
        {{ "OrderDetailsComponent.labelAccountingSystem" | translate }}
      </div>
    </div>
    <div class="col-12 col-md-6">
      <select
        class="form-control"
        id="accountingSystem"
        name="accountingSystem"
        [(ngModel)]="accountingSystem"
      >
        <option value="" disabled selected>
          {{ "general.firstOption" | translate }}
        </option>
        <option
          *ngFor="let accountingSystem of accountingSystemElements"
          [value]="accountingSystem.value"
        >
          {{ accountingSystem.label }}
        </option>
      </select>
    </div>
  </div>
  <div class="row mb-2 py-1">
    <div class="col-12 col-md-6 d-flex align-items-center">
      <div>
        {{ "OrderDetailsComponent.labelAccountingSystemInfo" | translate }}
      </div>
    </div>
    <div class="col-12 col-md-6">
      <input
        type="text"
        class="form-control"
        id="accountingSystemInfo"
        name="accountingSystemInfo"
        [(ngModel)]="accountingSystemInfo"
      />
    </div>
  </div>
  <div class="row py-1 bg-light">
    <div class="col-12 col-md-6 d-flex align-items-center">
      <div>{{ "OrderDetailsComponent.labelInternalNote" | translate }}</div>
    </div>
    <div class="col-12 col-md-6">
      <input
        type="text"
        class="form-control"
        id="internalNote"
        name="internalNote"
        [(ngModel)]="internalNote"
      />
    </div>
  </div>
</form>
