<form (ngSubmit)="submitData()" #advancedDetails="ngForm">
  <h3>{{ "AdvancedDetailsComponent.headline" | translate }}</h3>
  <hr class="border-0" />
  <div class="mb-2">
    <b>{{ "AdvancedDetailsComponent.defaultValues" | translate }}</b>
  </div>
  <div class="form-group mb-3">
    <label for="daysToDo">{{
      "AdvancedDetailsComponent.labelDaysToDo" | translate
    }}</label>
    <input
      type="text"
      class="form-control"
      id="daysToDo"
      name="daysToDo"
      ngModel
    />
  </div>
  <div class="form-group mb-3">
    <label for="daysOverdue">{{
      "AdvancedDetailsComponent.labelDaysOverdue" | translate
    }}</label>
    <input
      type="text"
      class="form-control"
      id="daysOverdue"
      name="daysOverdue"
      ngModel
    />
  </div>

  <div class="form-group mb-3">
    <label for="daysGracePeriod">{{
      "AdvancedDetailsComponent.labelDaysGracePeriod" | translate
    }}</label>
    <input
      type="text"
      class="form-control"
      id="daysGracePeriod"
      name="daysGracePeriod"
      ngModel
    />
  </div>
  <hr class="border-0" />
  <div class="mb-2">
    <b>{{ "AdvancedDetailsComponent.additionalDefaultValues" | translate }}</b>
  </div>
  <div class="form-group mb-3">
    <label for="additionalInstructions">
      {{ "AdvancedDetailsComponent.labelAdditionalInstructions" | translate }}
    </label>
    <textarea
      class="form-control"
      id="additionalInstructions"
      name="additionalInstructions"
      ngModel
    ></textarea>
  </div>

  <div class="form-group mb-3">
    <label for="initialNote">{{
      "AdvancedDetailsComponent.labelInitialNote" | translate
    }}</label>
    <textarea
      class="form-control"
      id="initialNote"
      name="initialNote"
      ngModel
    ></textarea>
  </div>
</form>
