<div class="container text-center mt-5">
  <div class="row">
    <div class="col">
      @if(error){
      <h1 class="display-5">{{ "ErrorpageComponent.error" | translate }}</h1>
      <div class="border rounded w-50 mx-auto p-3 mt-3">
        <code>
          <pre>{{ error }}</pre>
        </code>
      </div>
      }@else {
      <h1 class="display-1">404</h1>
      <h2 class="display-4">
        {{ "ErrorpageComponent.errorMessage" | translate }}
      </h2>
      <p class="lead">{{ "ErrorpageComponent.errorSubMessage" | translate }}</p>
      }
      <a
        [routerLink]="['/' + translateService.currentLang]"
        class="btn btn-primary mt-3"
        >{{ "ErrorpageComponent.homeButton" | translate }}</a
      >
    </div>
  </div>
</div>
