import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authUrl = `${environment.bierdeckelApiURL}/usercontext/IsLoggedOn`;

  constructor(
    private http: HttpClient,
    private navigationService: NavigationService
  ) {}

  public isAuthenticated(): boolean {
    // const storedExpirationTime = localStorage.getItem(
    //   environment.lsExpiresInKey
    // );
    const storedAccessToken = localStorage.getItem(
      environment.lsAccessTokenKey
    );
    // if (!storedAccessToken || !storedExpirationTime) return false;
    if (!storedAccessToken) return false;

    const isLoggedOn = this.isLoggedOn(storedAccessToken);
    // const currentTime = Date.now();
    // const isExpired = currentTime >= parseInt(storedExpirationTime, 10);

    // if (isLoggedOn && !isExpired) {
    if (isLoggedOn) {
      return true;
    } else {
      return false;
    }
  }

  public isLoggedOn(accessToken: string): Observable<boolean> {
    // Set the headers for the request
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });

    // Make the POST request and return the observable
    return this.http
      .post<boolean>(this.authUrl, { headers })
      .pipe(map((response) => response));
  }

  public logout(): void {
    this.navigationService.redirectOnLogout();

    localStorage.removeItem(environment.lsMandatorKey);
    localStorage.removeItem(environment.lsCustomerKey);
    localStorage.removeItem(environment.lsAccessTokenKey);
  }
}
