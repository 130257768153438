import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserInfo } from 'src/app/shared/models/bierdeckel-api.model';

@Injectable({
  providedIn: 'root',
})
export class BierdeckelApiService {
  private mandatorId = '00000000-0000-0000-0000-000000000000';
  private customerId = '00000000-0000-0000-0000-000000000000';
  public apiUrl = environment.bierdeckelApiURL;

  constructor(public http: HttpClient) {}

  public getMandatorId(): string {
    return this.mandatorId;
  }

  public setMandatorId(mandatorId: string): void {
    this.mandatorId = mandatorId;
  }

  public getCustomerId(): string {
    return this.customerId;
  }

  public setCustomerId(customerId: string): void {
    this.customerId = customerId;
  }

  public getUserInfo(authorization: string): Observable<UserInfo> {
    return this.makeHttpRequest(
      `${this.apiUrl}/usercontext/currentuser`,
      'GET',
      'json',
      authorization
    );
  }

  public getBrowserAndOS() {
    var browserInfo = this.getBrowserInfo();
    var os = this.getOS();
    return `WebBrowser ${browserInfo.name} (${os})`;
  }

  private getBrowserInfo() {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: tem[1] || '' };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edg)\/(\d+)/);
      if (tem != null)
        return {
          name: tem[1].replace('OPR', 'Opera').replace('Edg', 'Edge'),
          version: tem[2],
        };
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return { name: M[0], version: M[1] };
  }

  private getOS() {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (/Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }

  protected makeHttpRequest<T>(
    url: string,
    method: string,
    responseType: 'json' | 'text' = 'json', // Default response type is 'json'
    authorization?: string
  ): Observable<T> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (authorization) {
      headers = headers.set('Authorization', `Bearer ${authorization}`);
    }

    return this.http
      .request<T>(method, url, {
        headers,
        responseType: responseType as 'json', // Specify the response type
        observe: 'response', // Get the full response to check status
      })
      .pipe(
        map((response: HttpResponse<T>) => {
          return response.body as T; // Successful response
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(
            () => new Error(`Request failed: ${error.message}`)
          );
        })
      );
  }
}
