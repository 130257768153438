<h3>{{ "FiscalYearComponent.headline" | translate }}</h3>
<hr class="border-0" />
<div class="row">
  <div class="col-12 col-sm-4">
    <div class="d-flex justify-content-between">
      <button
        type="button"
        class="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target="#fiscalYearModal"
      >
        {{ "general.btnAdd" | translate }}
      </button>
      <button
        type="button"
        [disabled]="!isAnyYearRangeSelected()"
        (click)="deleteSelectedYearRange()"
        class="btn btn-primary btn-sm"
      >
        {{ "general.btnDelete" | translate }}
      </button>
      <button
        type="button"
        [disabled]="!isAnyYearRangeSelected()"
        (click)="editSelectedYearRange()"
        data-bs-toggle="modal"
        data-bs-target="#fiscalYearModal"
        class="btn btn-primary btn-sm"
      >
        {{ "general.btnEdit" | translate }}
      </button>
    </div>
  </div>
</div>
<table class="table table-striped">
  <thead>
    <tr>
      <th></th>
      <th>
        {{ "FiscalYearComponent.tableHeaders.newFiscalYear" | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="fiscalYearElements.length > 0; else noYears">
      <tr *ngFor="let yearRange of fiscalYearElements; let i = index">
        <td>
          <input
            type="checkbox"
            [checked]="isSelectedYearRange(i)"
            (change)="toggleYearRange(i, yearRange)"
            [attr.data-index]="i"
          />
        </td>
        <td>
          {{ yearRange.timeRange }}
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
<ng-template #noYears>
  <tr>
    <td colspan="5" class="text-center">
      {{ "FiscalYearComponent.noFiscalYear" | translate }}
    </td>
  </tr>
</ng-template>
<!-- Modal -->
<div
  class="modal fade"
  id="fiscalYearModal"
  tabindex="-1"
  aria-labelledby="fiscalYearModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="fiscalYearModalLabel">
          {{ "FiscalYearComponent.modalHeadline" | translate }}
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <form (ngSubmit)="addYearRange()" #yearRange="ngForm">
        <div class="modal-body">
          <div class="form-group">
            <label for="newFiscalYear">{{
              "FiscalYearComponent.labelNewFiscalYear" | translate
            }}</label>
            <input
              type="text"
              class="form-control"
              id="newFiscalYear"
              name="newFiscalYear"
              [(ngModel)]="fiscalYearTimeRange"
              placeholder="yyyy-MM - yyyy-MM"
              (change)="checkInput()"
            />
            <div class="text-danger" *ngIf="errorMessage">
              {{ errorMessage }}
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {{ "general.btnCancel" | translate }}
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="!validateFiscalYearRange(fiscalYearTimeRange)"
            data-bs-dismiss="modal"
          >
            {{ "general.btnAdd" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
