<div class="full-screen-container">
  <div class="row h-100 m-0">
    <div
      [routerLink]="['/' + translateService.currentLang + '/consultant/']"
      id="userTypeConsultant"
      class="col-6 border-end border-black border-4"
    >
      <div class="d-flex justify-content-center align-items-center h-100 m-0">
        <h1 class="text-white text-shadow">
          {{ "UserTypeSelectionComponent.consultant" | translate }}
        </h1>
      </div>
    </div>
    <div
      id="userTypeCustomer"
      [routerLink]="['/' + translateService.currentLang + '/customer/']"
      class="col-6 border-start border-black border-4"
    >
      <div class="d-flex justify-content-center align-items-center h-100 m-0">
        <h1 class="text-white text-shadow">
          {{ "UserTypeSelectionComponent.customer" | translate }}
        </h1>
      </div>
    </div>
  </div>
</div>
