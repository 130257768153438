import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MandatorService } from 'src/app/shared/services/mandator.service';
import { Mandator } from '../shared/models/mandator.model';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserInfo } from '../shared/models/bierdeckel-api.model';

@Component({
  selector: 'app-mandator-view',
  templateUrl: './mandator-view.component.html',
  styles: [
    `
      .table-responsible {
        overflow: auto;
      }
    `,
  ],
})
export class MandatorViewComponent implements OnInit {
  userInfo?: UserInfo;
  isLoading: boolean = true;
  selectedMandatorIndex: number = -1;
  mandators: Mandator[] = [
    {
      mandatorId: '1',
      editMode: false,
      status: '???',
      companyAndOrder: {
        companyNameLong: 'CompuMaster GmbH',
        companyNameShort: 'Test-Mandat',
        street: 'Et adipisci eum dele',
        postalCode: '56281',
        city: 'Emmelshause',
        country: 'Tempore mollit qui ',
        mail: 'nydy@mailinator.com',
        phone: '+1 (674) 118-3484',
        website: 'https://www.semejuxagocobit.cm',
        industry: 'Lorem dolore optio ',
        consultantName: 'rainer-dreier',
      },
      orderDetails: {
        orderFromMonth: 'apr',
        orderFromYear: 2022,
        orderToMonth: 'jan',
        orderToYear: 2023,
        orderStop: false,
        orderRhythm: 'annually',
        chartOfAccounts: 'SKR03_class8_noCheck',
        additionalAccountDigits: 'xxxx-0000',
        chartOfAccountsRule: 'Nesciunt eu dolor r',
        previousYearStructure: false,
        accountingType: 'taxBalance',
        accountingSystem: 'Sonstige_PDF',
        accountingSystemInfo: 'Vel ut ad maiores no',
        internalNote: 'Non occaecat earum d',
      },
      contactPerson: [
        {
          contactPersonId: '1',
          company: 'Molina and Doyle LLC',
          salutationStyle: 'Persönlich/ Du',
          salutation: 'frau',
          title: 'Molestias et velit q',
          firstname: 'Pandora',
          familyName: 'Angelica Rocha',
          nameAddition: 'Allistair Hobbs',
          mail: 'cazurufi@mailinator.com',
          phone: '+1 (621) 847-6766',
          mobile: 'Exercitation mollit ',
          fax: '+1 (124) 417-9246',
          companyPosition: 'Newman and Newman Associates',
          street: 'Odit quia voluptatum',
          postalCode: 'Autem atque ipsa au',
          location: 'Et velit voluptatem',
          country: 'Tempora officia dolo',
          website: 'https://www.xesyt.in',
          bdReportingRole: '',
          additionalRoleName: '',
          mainContact: false,
          additionalReportingRecipient: true,
          fibuRequest: true,
          fibuRequestCCAlways: false,
          fibuRequestCCReminder: true,
          taxConsultant: false,
          invoiceRecipient: true,
          otherRole: 'Ut iste consequatur ',
          note: 'Dolores dolor rerum ',
          reasonDatasaving: '21-Jul-2012',
          refDate: '19-Jun-1987',
          editMode: false,
        },
      ],
      fiscalYear: [
        {
          fiscalYearId: '1',
          timeRange: '2022-08 - 2024-08',
          editMode: false,
        },
      ],
      advancedDetails: {
        daysToDo: 6,
        daysOverdue: 16,
        daysGracePeriod: 27,
        additionalInstructions: 'Ducimus rerum numqu',
        initialNote: 'Neque autem fugit o',
      },
    },
  ];
  // Inject the authentication service into your component through the constructor
  constructor(
    private mandatorService: MandatorService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const authorization = localStorage.getItem(environment.lsAccessTokenKey);
    if (!authorization) throw new Error('no access toke found');

    this.mandatorService.getUserInfo(authorization).subscribe({
      next: (data: UserInfo) => {
        this.userInfo = data;
        this.isLoading = false;
      },
      error: (error) => {
        throw new Error('Error fetching user info:', error);
      },
    });
  }

  downloadControllingTool() {
    console.log('download Controlling Tool');
  }

  isSelectedMandatort(index: number): boolean {
    return index === this.selectedMandatorIndex;
  }

  toggleMandator(index: number, mandator: any) {
    this.selectedMandatorIndex = index;
    mandator.editMode = !mandator.editMode;
  }

  isAnyMandatorSelected(): boolean {
    return this.mandators.some((mandator) => mandator.editMode);
  }

  editSelectedMandator() {
    const selectedMandator = this.mandators.filter(
      (mandator) => mandator.editMode
    );
    this.router.navigate([`/edit-mandator/${selectedMandator[0].mandatorId}`]);
  }
}
