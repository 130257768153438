import { Component } from '@angular/core';

@Component({
  selector: 'app-support-button',
  templateUrl: './support-button.component.html',
  styleUrls: ['./support-button.component.css'],
})
export class SupportButtonComponent {
  showContextMenu = false;

  openContextMenu() {
    this.showContextMenu = !this.showContextMenu;
  }
}
