import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BierdeckelApiService } from './bierdeckel-api.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends BierdeckelApiService {
  public readonly defaultMandatorId = '00000000-0000-0000-0000-000000000000';
  public readonly defaultCustomerId = '00000000-0000-0000-0000-000000000000';

  loginCustomerAPI(
    mandatorId: string,
    customerId: string,
    loginName: string,
    password: string,
    machineName: string,
    applicationProcessID: number
  ): Observable<string> {
    const url = `${this.apiUrl}/${mandatorId}/Login/PasswordCredentials/Customer?customerGuid=${customerId}&loginName=${loginName}&password=${password}&machineName=${machineName}&applicationProcessID=${applicationProcessID}`;
    return this.makeHttpRequest(url, 'POST', 'text');
  }

  getCustomerList(mandatorId: string): Observable<void> {
    const url = `${this.apiUrl}/${mandatorId}/Customer/All/BasicItem`;
    return this.makeHttpRequest(url, 'GET');
  }

  getCustomer(mandatorId: string, customerId: string): Observable<void> {
    const url = `${this.apiUrl}/${mandatorId}/Customer/${customerId}/DisplayItem`;
    return this.makeHttpRequest(url, 'GET');
  }

  deleteCustomer(id: string) {
    return this.http.delete(this.apiUrl + '/' + id);
  }

  addCustomer(data: any) {
    return this.http.post(this.apiUrl, data);
  }

  updateCustomer(id: string, data: any) {
    return this.http.put(this.apiUrl + '/' + id, data);
  }
}
