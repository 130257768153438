import { Component } from '@angular/core';
import { FiscalYear } from 'src/app/shared/models/mandator.model';

@Component({
  selector: 'app-fiscal-year',
  templateUrl: './fiscal-year.component.html',
})
export class FiscalYearComponent {
  fiscalYearElements: FiscalYear[] = [];

  fiscalYearId = '';
  fiscalYearTimeRange = '';
  fiscalYearEditMode = false;
  selectedYearIndex = -1;
  errorMessage = '';

  addYearRange() {
    const newYearRange: FiscalYear = {
      fiscalYearId: this.fiscalYearId
        ? this.fiscalYearId
        : String(this.fiscalYearElements.length + 1),
      timeRange: this.fiscalYearTimeRange,
      editMode: this.fiscalYearEditMode ? this.fiscalYearEditMode : false,
    };

    const existingIndex = this.fiscalYearElements.findIndex(
      (yearRange) => yearRange.fiscalYearId === newYearRange.fiscalYearId
    );
    if (existingIndex !== -1) {
      // Update existing yearRange if the ID already exists
      this.fiscalYearElements[existingIndex] = newYearRange;
    } else {
      // Add the new yearRange if the ID doesn't exist
      this.fiscalYearElements.push(newYearRange);
    }
    this.resetForm();
  }

  resetForm() {
    this.fiscalYearId = '';
    this.fiscalYearTimeRange = '';
    this.fiscalYearEditMode = false;
  }

  // checks only the format
  validateFiscalYearFormat(inputValue: string): boolean {
    const regex = /^(\d{4}-(0[1-9]|1[0-2])) - (\d{4}-(0[1-9]|1[0-2]))$/;
    return regex.test(inputValue);
  }

  // checks both format and years
  validateFiscalYearRange(inputValue: string): boolean {
    const regex = /^(\d{4}-(0[1-9]|1[0-2])) - (\d{4}-(0[1-9]|1[0-2]))$/;
    const matches = inputValue.match(regex);

    // check if the first year range is > than second year range
    if (matches) {
      const firstYearRange = matches[1];
      const secondYearRange = matches[3];
      const firstYear = parseInt(firstYearRange.substring(0, 4));
      const secondYear = parseInt(secondYearRange.substring(0, 4));
      const firstMonth = parseInt(firstYearRange.substring(5, 7));
      const secondMonth = parseInt(secondYearRange.substring(5, 7));

      return (
        secondYear > firstYear ||
        (secondYear === firstYear && secondMonth > firstMonth)
      );
    }

    return false;
  }

  checkInput() {
    if (!this.validateFiscalYearFormat(this.fiscalYearTimeRange)) {
      this.errorMessage =
        'Falsches Format! Bitte geben Sie den neuen Zeitbereich des Geschäftsjahres im Format "yyyy-MM - yyyy-MM" an. yyyy bezieht sich auf das Jahr und MM auf den Monat.';
    } else if (!this.validateFiscalYearRange(this.fiscalYearTimeRange)) {
      this.errorMessage = 'Zweites Jahr muss größer sein als das erste!';
    } else {
      this.errorMessage = '';
    }
  }

  isSelectedYearRange(index: number): boolean {
    return index === this.selectedYearIndex;
  }

  toggleYearRange(index: number, yearRange: any) {
    this.selectedYearIndex = index;
    yearRange.editMode = !yearRange.editMode;
  }

  isAnyYearRangeSelected(): boolean {
    return this.fiscalYearElements.some((yearRange) => yearRange.editMode);
  }

  deleteSelectedYearRange() {
    const selectedYearRange = this.fiscalYearElements.filter(
      (yearRange) => yearRange.editMode
    );
    const index = this.fiscalYearElements.indexOf(selectedYearRange[0]);
    this.fiscalYearElements.splice(index, 1);
    this.selectedYearIndex = -1;
  }

  editSelectedYearRange() {
    const selectedYear = this.fiscalYearElements.filter(
      (yearRange) => yearRange.editMode
    );
    this.fiscalYearId = selectedYear[0].fiscalYearId;
    this.fiscalYearTimeRange = selectedYear[0].timeRange;
    this.fiscalYearEditMode = selectedYear[0].editMode;
  }

  submitData() {
    return this.fiscalYearElements;
  }
}
