import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public readonly DEFAULT_LANGUAGE: string = 'de';
  private readonly supportedLanguages = ['en', 'de'];

  constructor(
    private router: Router,
    private translateService: TranslateService
  ) {}

  /**
   * handle if the current language is not supported. Set default language (de).
   */
  handleLanguage() {
    const currentLang = this.translateService.currentLang;
    if (!this.supportedLanguages.includes(currentLang)) {
      this.translateService.setDefaultLang(this.DEFAULT_LANGUAGE);
      this.translateService.use(this.DEFAULT_LANGUAGE);
    }
  }

  initRouter(router: Router) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const urlSegments = this.router.url.split('/');
        const lang = urlSegments[1];
        if (lang) {
          this.translateService.use(lang);
        } else {
          this.translateService.use('de'); // default language
        }
      });
  }
}
