<div
  class="full-screen-container d-flex align-content-between flex-wrap justify-content-center pt-5"
>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-7 col-12">
        <h1 class="text-white text-center mb-5">
          {{ "IdentityCheckComponent.headline" | translate }}
        </h1>
      </div>
      <div class="col-lg-7 col-12">
        <div class="bg-white p-3 rounded">
          <h2>
            {{ "IdentityCheckComponent.formularTitle" | translate }}
          </h2>
          <form (ngSubmit)="proceedToLogin()">
            <div class="form-group mb-3">
              <label for="mandatorId">{{
                "IdentityCheckComponent.labelMandatorId" | translate
              }}</label>
              <input
                type="text"
                class="form-control"
                id="mandatorId"
                name="mandatorId"
                autocomplete="login mandatorId"
                [(ngModel)]="mandatorId"
              />
            </div>
            @if(router.url === '/' + translateService.currentLang +
            '/customer'){
            <div class="form-group mb-3">
              <label for="customerId">{{
                "IdentityCheckComponent.labelCustomerId" | translate
              }}</label>
              <input
                type="text"
                class="form-control"
                id="customerId"
                name="customerId"
                autocomplete="login customer customerId"
                [(ngModel)]="customerId"
              />
            </div>
            }
            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-secondary me-3"
                [routerLink]="['/' + translateService.currentLang + '/']"
              >
                {{ "general.btnCancel" | translate }}
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                style="width: 71px"
                [disabled]="checkSubmit()"
              >
                {{ "general.btnNext" | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <app-copyright-footer
      textColor="text-secondary"
      userPortalType="Anonymous"
    ></app-copyright-footer>
  </div>
</div>
