import { Component, OnInit } from '@angular/core';

// Import the AuthService type from the SDK
import { TranslateService } from '@ngx-translate/core';
import { MandatorService } from 'src/app/shared/services/mandator.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  isNavbarCollapsed: boolean = true;
  mandatorId?: string | null;
  // Inject the authentication service into your component through the constructor
  constructor(
    public mandatorService: MandatorService,
    public translateService: TranslateService,
    public authService: AuthService
  ) {}

  toggleNavbar() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  ngOnInit(): void {
    this.mandatorId = localStorage.getItem('mandator_id');
  }
}
