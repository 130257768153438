import { Component, OnInit } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { LanguageService } from './shared/services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(
    private languageService: LanguageService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.languageService.handleLanguage();
  }
}
